import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  serverTimestamp
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { validateBill } from '../utils/validationUtils';
import { withRateLimit } from '../utils/firebaseMiddleware';
import { AuditLogger } from '../utils/auditLogger';
import SearchableSelect from '../components/SearchableSelect';
import SearchableModal from '../components/SearchableModal';
import SearchableDropdown from '../components/SearchableDropdown';
import BillCard from '../components/BillCard';
import BillModal from '../components/BillModal';
import PaymentSummary from '../components/PaymentSummary';

Modal.setAppElement("#root");

function Home() {

const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
const [isEmployeeModalOpen, setEmployeeModalOpen] = useState(false);
const [isServiceModalOpen, setServiceModalOpen] = useState(false);
const [customerSearch, setCustomerSearch] = useState(""); // Müşteri arama state'i
const [applyDiscount, setApplyDiscount] = useState(false); // İndirim kutucuğu durumu
const [discountAmount, setDiscountAmount] = useState(0); // İndirim tutarı

  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [bills, setBills] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [services, setServices] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [billForm, setBillForm] = useState({
    date: new Date().toISOString().split('T')[0],
    customerId: "",
    employeeId: "",
    services: [],
    price: "",
    discount: "",
    paymentType: "Nakit",
    status: "active",
  });

useEffect(() => {
  const loadAllData = async () => {
    try {
      // Diğer veri çekme işlemleri aynı kalıyor
      const [customerSnapshot, employeeSnapshot, serviceSnapshot] = await Promise.all([
        getDocs(query(collection(db, "users"), where("role", "==", "customer"))),
        getDocs(query(collection(db, "users"), where("role", "==", "employee"))),
        getDocs(collection(db, "services"))
      ]);

      const customersData = customerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const employeesData = employeeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const servicesData = serviceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setCustomers(customersData);
      setEmployees(employeesData);
      setServices(servicesData);

      // Bills sorgusunu fetchBills ile aynı mantıkta yapıyoruz
      const billsRef = collection(db, "bills");
      let billsQuery;

      if (user.role === 'cashier') {
        const today = new Date().toISOString().split('T')[0];
        billsQuery = query(
          billsRef,
          where("status", "==", "active"),
          where("date", "==", today)  // Tam eşitlik kontrolü kullanıyoruz
        );
      } else {
        billsQuery = query(billsRef, where("status", "==", "active"));
      }

      const billsSnapshot = await getDocs(billsQuery);
      const billsData = billsSnapshot.docs.map((doc) => {
        const billData = doc.data();
        const customer = customersData.find((c) => c.id === billData.customerId);
        const employee = employeesData.find((e) => e.id === billData.employeeId);
        const serviceNames = billData.services
          ? billData.services.map(service => service.name).join(", ")
          : "Bilinmiyor";

        return {
          id: doc.id,
          ...billData,
          customerName: customer ? `${customer.name} ${customer.surname}` : "Bilinmiyor",
          employeeName: employee ? `${employee.name} ${employee.surname}` : "Bilinmiyor",
          serviceNames: serviceNames
        };
      });

      setBills(billsData);
    } catch (error) {
      console.error("Veri yükleme hatası:", error);
    }
  };

  loadAllData();
}, [user.role]);

  const isToday = (dateStr) => {
    const today = new Date();
    const billDate = new Date(dateStr);
    return (
      billDate.getDate() === today.getDate() &&
      billDate.getMonth() === today.getMonth() &&
      billDate.getFullYear() === today.getFullYear()
    );
  };

  const getDateColor = (dateStr) => {
    return isToday(dateStr) ? "#4CAF50" : "#FF9800";
  };

  const fetchCustomers = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("role", "==", "customer"))
      );
      setCustomers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Müşteri verisi çekme hatası:", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("role", "==", "employee"))
      );
      setEmployees(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Çalışan verisi çekme hatası:", error);
    }
  };

  const fetchServices = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "services"));
      setServices(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("İşlem verisi çekme hatası:", error);
    }
  };

const fetchBills = async () => {
  try {
    const billsRef = collection(db, "bills");
    let billsQuery;

    if (user.role === 'cashier') {
      // Bugünün tarihini YYYY-MM-DD formatında al
      const today = new Date().toISOString().split('T')[0];  // "2024-12-14" formatında
      
      billsQuery = query(
        billsRef,
        where("status", "==", "active"),
        where("date", "==", today)  // Tam eşitlik kontrolü yap
      );
    } else {
      billsQuery = query(billsRef, where("status", "==", "active"));
    }

    const billsSnapshot = await getDocs(billsQuery);
    
    const billsData = billsSnapshot.docs.map((doc) => {
      const billData = doc.data();
      const customer = customers.find((c) => c.id === billData.customerId);
      const employee = employees.find((e) => e.id === billData.employeeId);
      
      return {
        id: doc.id,
        ...billData,
        customerName: customer ? `${customer.name} ${customer.surname}` : "Bilinmiyor",
        employeeName: employee ? `${employee.name} ${employee.surname}` : "Bilinmiyor",
        serviceNames: billData.services?.map(s => s.name).join(", ") || "Bilinmiyor"
      };
    });

    setBills(billsData);
  } catch (error) {
    console.error("Adisyonları getirirken hata oluştu:", error);
  }
};

const handleOpenModal = (bill = null) => {
  setModalIsOpen(true);
  if (bill) {
    // Düzenleme modu
    setIsEditing(true);
    setSelectedBillId(bill.id);
    
    // Seçili müşteri, çalışan ve işlem bilgilerini bul
    const selectedCustomer = customers.find(c => c.id === bill.customerId);
    const selectedEmployee = employees.find(e => e.id === bill.employeeId);
    const selectedService = bill.services && bill.services[0]; // İlk servisi al
    
    // Form bilgilerini ayarla
    setBillForm({
      date: bill.date,
      customerId: bill.customerId,
      employeeId: bill.employeeId,
      serviceId: selectedService?.id,
      price: bill.price,
      discount: bill.discount || 0,
      paymentType: bill.paymentType || "Nakit",
      status: bill.status,
      // SearchableDropdown için gerekli
      selectedCustomer: selectedCustomer,
      selectedEmployee: selectedEmployee,
    });

    // İndirim bilgilerini ayarla
    setApplyDiscount(bill.discount > 0);
    setDiscountAmount(bill.discount || 0);
    
    // Seçili servisleri ayarla
    setSelectedServices(bill.services || []);
  } else {
    // Yeni adisyon modu
    setIsEditing(false);
    setSelectedBillId(null);
    setBillForm({
      date: new Date().toISOString().split('T')[0],
      customerId: "",
      employeeId: "",
      services: [],
      price: "",
      discount: "",
      paymentType: "Nakit",
      status: "active",
      selectedCustomer: null,
      selectedEmployee: null
    });
    setSelectedServices([]);
    setApplyDiscount(false);
    setDiscountAmount(0);
  }
};

  const handleDeleteBill = async (billId) => {
    if (window.confirm('Bu adisyonu silmek istediğinize emin misiniz?')) {
      try {
        const billRef = doc(db, "bills", billId);
        await updateDoc(billRef, { 
          status: "deleted",
          updatedAt: serverTimestamp()
        });
        
        await AuditLogger.log(
          user.uid,
          'delete',
          'bill',
          billId,
          { status: 'deleted' }
        );
        
        alert("Adisyon silindi.");
        fetchBills();
      } catch (error) {
        console.error("Silme hatası:", error);
        alert("Silme işlemi sırasında bir hata oluştu.");
      }
    }
  };

const handleSaveBillData = async () => {
  const { isValid, errors } = validateBill(billForm);
  if (!isValid) {
    alert(Object.values(errors).join("\n"));
    return;
  }

  try {
    const selectedService = selectedServices[0];
    if (!selectedService) {
      alert("Lütfen geçerli bir işlem seçin.");
      return;
    }

    const calculatedDiscount = applyDiscount ? discountAmount : 0;
    const finalTotalPrice = selectedService.price - calculatedDiscount;

    const billData = {
      customerId: billForm.customerId,
      employeeId: billForm.employeeId,
      paymentType: billForm.paymentType,
      date: billForm.date,
      price: selectedService.price,
      discount: calculatedDiscount,
      totalPrice: finalTotalPrice,
      services: [
        {
          id: selectedService.id,
          name: selectedService.name,
          price: selectedService.price,
        },
      ],
      status: "active",
      userId: user.uid,
    };

    await withRateLimit(user.uid, async () => {
      if (isEditing) {
        await updateDoc(doc(db, "bills", selectedBillId), {
          ...billData,
          updatedAt: serverTimestamp(),
        });
      } else {
        await addDoc(collection(db, "bills"), {
          ...billData,
          createdAt: serverTimestamp(),
        });
      }
    });

    await fetchBills();
    setModalIsOpen(false);
    alert(isEditing ? "Adisyon güncellendi!" : "Yeni adisyon eklendi!");
  } catch (error) {
    console.error("İşlem hatası:", error);
    alert(`Bir hata oluştu: ${error.message}`);
  }
};

  const handleCustomerSelect = (customer) => {
    setBillForm(prev => ({ ...prev, customerId: customer.id }));
  };

  const handleEmployeeSelect = (employee) => {
    setBillForm(prev => ({ ...prev, employeeId: employee.id }));
  };

  const handleServicesSelect = (services) => {
    setBillForm(prev => ({
      ...prev,
      services: services,
      price: services.reduce((total, service) => total + Number(service.price), 0)
    }));
    setSelectedServices(services);
  };

const today = new Date().toISOString().split('T')[0];

// Adisyonları tarihe göre gruplama
const groupBillsByDate = (bills) => {
  return bills.reduce((groups, bill) => {
    const date = bill.date;
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(bill);
    return groups;
  }, {});
};

return (
  <div className="p-6">
    {/* Adisyon Ekleme Butonu */}
    <button 
      onClick={() => handleOpenModal()}
      className="flex items-center px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300 mb-6"
    >
      + Adisyon Ekle
    </button>

    {/* Ödeme Özeti */}
    <PaymentSummary bills={bills} />

    {/* Bugünün Adisyonları */}
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Bugünün Adisyonları
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {bills
          .filter(bill => bill.date === today)
          .map((bill) => (
            <BillCard
              key={bill.id}
              bill={bill}
              onEdit={handleOpenModal}
              onDelete={handleDeleteBill}
            />
          ))}
        {bills.filter(bill => bill.date === today).length === 0 && (
          <p className="text-gray-500 col-span-full text-center py-4">
            Bugün için henüz adisyon bulunmuyor.
          </p>
        )}
      </div>
    </div>

    {/* Geçmiş Adisyonlar - Sadece admin rolü için */}
    {user.role === 'admin' && bills.some(bill => bill.date !== today) && (
      <>
        <div className="border-t border-gray-200 my-8"></div>
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Geçmiş Adisyonlar
          </h2>
          {Object.entries(groupBillsByDate(bills.filter(bill => bill.date !== today)))
            .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
            .map(([date, dateBills]) => (
              <div key={date} className="mb-8">
                <h3 className="text-lg font-medium mb-4 text-gray-700">
                  {new Date(date).toLocaleDateString('tr-TR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {dateBills.map((bill) => (
                    <BillCard
                      key={bill.id}
                      bill={bill}
                      onEdit={handleOpenModal}
                      onDelete={handleDeleteBill}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </>
    )}

    {/* BillModal */}
    <BillModal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      billForm={billForm}
      setBillForm={setBillForm}
      onBillFormChange={(field, value) => setBillForm(prev => ({ ...prev, [field]: value }))}
      onSave={handleSaveBillData}
      isEditing={isEditing}
      applyDiscount={applyDiscount}
      setApplyDiscount={setApplyDiscount}
      discountAmount={discountAmount}
      setDiscountAmount={setDiscountAmount}
      selectedServices={selectedServices}
      setSelectedServices={setSelectedServices}
      customers={customers}
      employees={employees}
      services={services}
    />
  </div>
);
}

export default Home;