import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Search, UserPlus, Edit2, Trash2, User, Mail, Phone, X, Save } from 'lucide-react';

Modal.setAppElement("#root");

function Customers() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [customerForm, setCustomerForm] = useState({
    name: "",
    surname: "",
    emailAddress: "",
    phone: "",
    role: "customer",
    status: "active",
  });

  // Müşteri verilerini çekme
  const fetchCustomers = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("role", "==", "customer"),
        where("status", "==", "active")
      );
      const querySnapshot = await getDocs(q);
      const customerData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomers(customerData);
      setFilteredCustomers(customerData); // Arama için başlangıç verisi
    } catch (error) {
      console.error("Müşteri verileri çekilirken hata:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  // Arama fonksiyonu
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = customers.filter(
      (customer) =>
        customer.name.toLowerCase().includes(value) ||
        customer.emailAddress.toLowerCase().includes(value)
    );
    setFilteredCustomers(filtered);
  };

  // Modal açma/kapatma
  const openModal = (customer = null) => {
    setModalIsOpen(true);
    if (customer) {
      setIsEditing(true);
      setSelectedCustomerId(customer.id);
      setCustomerForm({ ...customer });
    } else {
      setIsEditing(false);
      setSelectedCustomerId(null);
      setCustomerForm({
        name: "",
        surname: "",
        emailAddress: "",
        phone: "",
        role: "customer",
        status: "active",
      });
    }
  };

  const closeModal = () => setModalIsOpen(false);

  // Müşteri ekleme/düzenleme
const handleSaveCustomer = async () => {
  try {
    // Benzersiz alanları kontrol et (isim, e-posta, telefon)
    const customersRef = collection(db, "users");
    const duplicateQuery = query(
      customersRef,
      where("status", "==", "active"),
      where("role", "==", "customer"),
      where("emailAddress", "==", customerForm.emailAddress) // E-posta kontrolü
    );

    const querySnapshot = await getDocs(duplicateQuery);

    // Eğer düzenleme yapıyorsak, mevcut müşterinin ID'sini hariç tut
    const isDuplicate = querySnapshot.docs.some(
      (doc) => doc.id !== selectedCustomerId
    );

    if (isDuplicate) {
      alert("Bu e-posta adresiyle kayıtlı başka bir müşteri var!");
      return; // İşlemi durdur
    }

    // Eğer benzersizse ekleme veya düzenleme işlemi
    if (isEditing) {
      const customerRef = doc(db, "users", selectedCustomerId);
      await updateDoc(customerRef, customerForm);
      alert("Müşteri başarıyla güncellendi!");
    } else {
      await addDoc(customersRef, customerForm);
      alert("Yeni müşteri başarıyla eklendi!");
    }

    fetchCustomers();
    closeModal();
  } catch (error) {
    console.error("Müşteri kaydedilirken hata:", error);
  }
};

  // Müşteri silme (soft delete)
  const handleDeleteCustomer = async (customerId) => {
    try {
      const customerRef = doc(db, "users", customerId);
      await updateDoc(customerRef, { status: "deleted" });
      fetchCustomers();
    } catch (error) {
      console.error("Müşteri silinirken hata:", error);
    }
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        {/* Arama Alanı */}
        <div className="relative w-full md:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Müşteri Ara (isim veya e-posta)"
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Yeni Müşteri Butonu */}
        <button
          onClick={() => openModal()}
          className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
        >
          <UserPlus className="h-5 w-5" />
          Yeni Müşteri
        </button>
      </div>

      {/* Müşteri Tablosu */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Soyad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefon</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">İşlemler</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredCustomers.map((customer) => (
                <tr key={customer.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.surname}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.emailAddress}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.phone}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button
                      onClick={() => openModal(customer)}
                      className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors mr-2"
                    >
                      <Edit2 className="h-4 w-4 mr-1" />
                      Düzenle
                    </button>
                    <button
                      onClick={() => handleDeleteCustomer(customer.id)}
                      className="inline-flex items-center px-3 py-1 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
                    >
                      <Trash2 className="h-4 w-4 mr-1" />
                      Sil
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Müşteri Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '500px',
            width: '90%',
            margin: '0 auto',
            padding: 0,
            border: 'none',
            background: 'transparent',
            overflow: 'visible'
          }
        }}
      >
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          {/* Modal Header */}
          <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white">
              {isEditing ? "Müşteri Düzenle" : "Yeni Müşteri"}
            </h2>
            <button
              onClick={closeModal}
              className="text-white hover:text-gray-200 transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Body */}
          <div className="p-6 space-y-4">
            {/* Ad */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="h-4 w-4 text-blue-500" />
                Ad
              </label>
              <input
                type="text"
                placeholder="Ad"
                value={customerForm.name}
                onChange={(e) => setCustomerForm({ ...customerForm, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Soyad */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="h-4 w-4 text-blue-500" />
                Soyad
              </label>
              <input
                type="text"
                placeholder="Soyad"
                value={customerForm.surname}
                onChange={(e) => setCustomerForm({ ...customerForm, surname: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Email */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Mail className="h-4 w-4 text-blue-500" />
                Email
              </label>
              <input
                type="email"
                placeholder="Email"
                value={customerForm.emailAddress}
                onChange={(e) => setCustomerForm({ ...customerForm, emailAddress: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Telefon */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Phone className="h-4 w-4 text-blue-500" />
                Telefon
              </label>
              <input
                type="text"
                placeholder="Telefon"
                value={customerForm.phone}
                onChange={(e) => setCustomerForm({ ...customerForm, phone: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          {/* Modal Footer */}
          <div className="bg-gray-50 px-6 py-4 flex justify-end gap-3">
            <button
              onClick={closeModal}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
            >
              İptal
            </button>
            <button
              onClick={handleSaveCustomer}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
            >
              <Save className="h-4 w-4" />
              {isEditing ? "Güncelle" : "Kaydet"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Customers;
