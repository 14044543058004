import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Search, UserPlus, Edit2, Trash2, User, Mail, Phone, X, Save, Briefcase } from 'lucide-react';

Modal.setAppElement("#root");

function Employee() {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeeForm, setEmployeeForm] = useState({
    name: "",
    surname: "",
    emailAddress: "",
    phone: "",
    role: "employee",
    status: "active",
  });

  // Çalışanları Firestore'dan çek
  const fetchEmployees = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("role", "==", "employee"),
        where("status", "==", "active")
      );
      const querySnapshot = await getDocs(q);
      const employeesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEmployees(employeesData);
      setFilteredEmployees(employeesData);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Arama çubuğu
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = employees.filter(
      (employee) =>
        employee.name.toLowerCase().includes(value) ||
        employee.emailAddress.toLowerCase().includes(value)
    );
    setFilteredEmployees(filtered);
  };

  // Modal açma/kapatma
  const openModal = (employee = null) => {
    setModalIsOpen(true);
    if (employee) {
      setIsEditing(true);
      setSelectedEmployeeId(employee.id);
      setEmployeeForm({
        name: employee.name,
        surname: employee.surname,
        emailAddress: employee.emailAddress,
        phone: employee.phone,
        role: "employee",
        status: employee.status,
      });
    } else {
      setIsEditing(false);
      setSelectedEmployeeId(null);
      setEmployeeForm({
        name: "",
        surname: "",
        emailAddress: "",
        phone: "",
        role: "employee",
        status: "active",
      });
    }
  };

  const closeModal = () => setModalIsOpen(false);

  // Çalışan ekleme/düzenleme
  const handleSaveEmployee = async () => {
    try {
      const employeesRef = collection(db, "users");

      // Benzersiz kontrol: İsim, e-posta veya telefon
      const duplicateQuery = query(
        employeesRef,
        where("status", "==", "active"),
        where("role", "==", "employee"),
        where("emailAddress", "==", employeeForm.emailAddress)
      );
      const querySnapshot = await getDocs(duplicateQuery);

      const isDuplicate = querySnapshot.docs.some(
        (doc) => doc.id !== selectedEmployeeId
      );

      if (isDuplicate) {
        alert("Bu e-posta adresine sahip başka bir çalışan zaten var!");
        return;
      }

      if (isEditing) {
        const employeeRef = doc(db, "users", selectedEmployeeId);
        await updateDoc(employeeRef, employeeForm);
        alert("Çalışan güncellendi!");
      } else {
        await addDoc(employeesRef, employeeForm);
        alert("Yeni çalışan eklendi!");
      }

      fetchEmployees();
      closeModal();
    } catch (error) {
      console.error("Çalışan kaydedilirken hata:", error);
    }
  };

  // Soft delete (Silme)
  const handleDeleteEmployee = async (employeeId) => {
    try {
      const employeeRef = doc(db, "users", employeeId);
      await updateDoc(employeeRef, { status: "deleted" });
      alert("Çalışan silindi (soft delete).");
      fetchEmployees();
    } catch (error) {
      console.error("Silme hatası:", error);
    }
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        {/* Arama Alanı */}
        <div className="relative w-full md:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Çalışan Ara (isim veya e-posta)"
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Yeni Çalışan Butonu */}
        <button
          onClick={() => openModal()}
          className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
        >
          <UserPlus className="h-5 w-5" />
          Yeni Çalışan
        </button>
      </div>

      {/* Çalışan Tablosu */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Soyad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefon</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">İşlemler</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredEmployees.map((employee) => (
                <tr key={employee.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">{employee.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.surname}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.emailAddress}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.phone}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button
                      onClick={() => openModal(employee)}
                      className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors mr-2"
                    >
                      <Edit2 className="h-4 w-4 mr-1" />
                      Düzenle
                    </button>
                    <button
                      onClick={() => handleDeleteEmployee(employee.id)}
                      className="inline-flex items-center px-3 py-1 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
                    >
                      <Trash2 className="h-4 w-4 mr-1" />
                      Sil
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Çalışan Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '500px',
            width: '90%',
            margin: '0 auto',
            padding: 0,
            border: 'none',
            background: 'transparent',
            overflow: 'visible'
          }
        }}
      >
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          {/* Modal Header */}
          <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white flex items-center gap-2">
              <Briefcase className="h-5 w-5" />
              {isEditing ? "Çalışanı Düzenle" : "Yeni Çalışan"}
            </h2>
            <button
              onClick={closeModal}
              className="text-white hover:text-gray-200 transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Body */}
          <div className="p-6 space-y-4">
            {/* Ad */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="h-4 w-4 text-blue-500" />
                Ad
              </label>
              <input
                type="text"
                placeholder="Ad"
                value={employeeForm.name}
                onChange={(e) => setEmployeeForm({ ...employeeForm, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Soyad */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="h-4 w-4 text-blue-500" />
                Soyad
              </label>
              <input
                type="text"
                placeholder="Soyad"
                value={employeeForm.surname}
                onChange={(e) => setEmployeeForm({ ...employeeForm, surname: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Email */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Mail className="h-4 w-4 text-blue-500" />
                Email
              </label>
              <input
                type="email"
                placeholder="Email"
                value={employeeForm.emailAddress}
                onChange={(e) => setEmployeeForm({ ...employeeForm, emailAddress: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Telefon */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Phone className="h-4 w-4 text-blue-500" />
                Telefon
              </label>
              <input
                type="text"
                placeholder="Telefon"
                value={employeeForm.phone}
                onChange={(e) => setEmployeeForm({ ...employeeForm, phone: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          {/* Modal Footer */}
          <div className="bg-gray-50 px-6 py-4 flex justify-end gap-3">
            <button
              onClick={closeModal}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
            >
              İptal
            </button>
            <button
              onClick={handleSaveEmployee}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
            >
              <Save className="h-4 w-4" />
              {isEditing ? "Güncelle" : "Kaydet"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Employee;
