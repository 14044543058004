import React from 'react';

const PaymentSummary = ({ bills }) => {
  const today = new Date().toISOString().split('T')[0];
  
  // Bugüne ait adisyonları filtrele
  const todaysBills = bills.filter(bill => bill.date === today);
  
  // Ödeme tiplerine göre toplam tutarları hesapla
  const totals = todaysBills.reduce((acc, bill) => {
    const paymentType = bill.paymentType || 'Diğer';
    const totalPrice = Number(bill.totalPrice) || 0;
    
    acc[paymentType] = (acc[paymentType] || 0) + totalPrice;
    return acc;
  }, {});
  
  // Gösterilecek ödeme tipleri
  const paymentTypes = {
    'Nakit': 'bg-green-100 text-green-800 border-green-200',
    'Kredi Kartı': 'bg-blue-100 text-blue-800 border-blue-200',
    'Havale': 'bg-purple-100 text-purple-800 border-purple-200',
    'Açık Hesap': 'bg-yellow-100 text-yellow-800 border-yellow-200',
    'Açık Hesap Ödeme': 'bg-orange-100 text-orange-800 border-orange-200'
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
      {Object.entries(paymentTypes).map(([type, colorClass]) => (
        <div 
          key={type} 
          className={`p-4 rounded-lg border ${colorClass} shadow-sm`}
        >
          <div className="text-sm font-medium">{type}</div>
          <div className="text-lg font-bold mt-1">
            {((totals[type] || 0).toLocaleString('tr-TR'))} TL
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaymentSummary;