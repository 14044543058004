import React from 'react';
import Modal from 'react-modal';
import { Calendar, User, Briefcase, FileText, CreditCard, Percent, X } from 'lucide-react';
import SearchableDropdown from './SearchableDropdown'

const BillModal = ({
  isOpen,
  onClose,
  billForm,
  setBillForm, // Yeni prop
  onBillFormChange,
  onSave,
  isEditing,
  applyDiscount,
  setApplyDiscount,
  discountAmount,
  setDiscountAmount,
  selectedServices,
  setSelectedServices, // Yeni prop
  customers,
  employees,
  services,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        },
        content: {
          position: 'relative',
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: 'auto',
          maxWidth: '600px',
          width: '90%',
          maxHeight: '90vh',
          margin: '0 auto',
          padding: 0,
          border: 'none',
          background: 'transparent',
          overflow: 'visible'
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-h-[90vh] overflow-y-auto">
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-xl font-semibold text-white">
            {isEditing ? 'Adisyonu Düzenle' : 'Yeni Adisyon'}
          </h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-6 space-y-6">
          {/* Tarih Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <Calendar className="h-4 w-4 text-blue-500" />
              Tarih
            </label>
            <input
              type="date"
              value={billForm.date}
              onChange={(e) => onBillFormChange('date', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
            />
          </div>

{/* Müşteri Seçimi */}
<div className="space-y-2">
  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
    <User className="h-4 w-4 text-blue-500" />
    Müşteri
  </label>
  <SearchableDropdown
    items={customers}
    onSelect={(customer) =>
      setBillForm((prev) => ({
        ...prev,
        customerId: customer.id,
        selectedCustomer: customer
      }))
    }
    placeholder="Müşteri Ara..."
    value={billForm.selectedCustomer}
    icon={User}
  />
</div>

{/* Çalışan Seçimi */}
<div className="space-y-2">
  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
    <Briefcase className="h-4 w-4 text-blue-500" />
    Çalışan
  </label>
  <SearchableDropdown
    items={employees}
    onSelect={(employee) =>
      setBillForm((prev) => ({
        ...prev,
        employeeId: employee.id,
        selectedEmployee: employee
      }))
    }
    placeholder="Çalışan Ara..."
    value={billForm.selectedEmployee}
    icon={Briefcase}
  />
</div>

{/* İşlem Seçimi */}
<div className="space-y-2">
  <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
    <FileText className="h-4 w-4 text-blue-500" />
    İşlem
  </label>
  <SearchableDropdown
    items={services}
    onSelect={(service) => {
      setBillForm((prev) => ({
        ...prev,
        services: [service],
        serviceId: service.id,
        price: service.price,
        name: service.name
      }));
      setSelectedServices([service]);
    }}
    placeholder="İşlem Ara..."
    value={selectedServices[0]}
    icon={FileText}
  />
</div>

          {/* Fiyat Bilgisi */}
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="text-lg font-medium text-gray-900 mb-4 flex items-center justify-between">
              <span>İşlem Tutarı:</span>
              <span>{billForm.price || 0} TL</span>
            </div>

            {/* İndirim Seçenekleri */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="applyDiscount"
                  checked={applyDiscount}
                  onChange={(e) => {
                    setApplyDiscount(e.target.checked);
                    if (!e.target.checked) setDiscountAmount(0);
                  }}
                  className="h-4 w-4 text-blue-500 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="applyDiscount" className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <Percent className="h-4 w-4 text-blue-500" />
                  İndirim Uygula
                </label>
              </div>

              {applyDiscount && (
                <input
                  type="number"
                  placeholder="İndirim Tutarı"
                  value={discountAmount}
                  onChange={(e) => setDiscountAmount(Number(e.target.value))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                />
              )}
            </div>
          </div>

          {/* Ödeme Türü */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <CreditCard className="h-4 w-4 text-blue-500" />
              Ödeme Türü
            </label>
            <select
              value={billForm.paymentType}
              onChange={(e) => onBillFormChange('paymentType', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
            >
              <option value="Nakit">Nakit</option>
              <option value="Kredi Kartı">Kredi Kartı</option>
              <option value="Havale">Havale</option>
              <option value="Açık Hesap">Açık Hesap</option>
              <option value="Açık Hesap Ödeme">Açık Hesap Ödeme</option>
            </select>
          </div>

          {/* Toplam Tutar */}
          <div className="bg-gradient-to-r from-cyan-50 to-blue-50 rounded-lg p-4">
            <div className="text-lg font-medium flex items-center justify-between">
              <span>Toplam Tutar:</span>
              {applyDiscount && discountAmount > 0 ? (
                <div className="text-right">
                  <span className="line-through text-gray-400 text-base">
                    {billForm.price || 0} TL
                  </span>
                  <span className="ml-2 text-green-600">
                    {(billForm.price || 0) - discountAmount} TL
                  </span>
                </div>
              ) : (
                <span>{billForm.price || 0} TL</span>
              )}
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
          >
            İptal
          </button>
          <button
            onClick={onSave}
            className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
          >
            {isEditing ? 'Güncelle' : 'Kaydet'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BillModal;