import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Navigation from "./components/Navigation";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Customers from "./pages/Customers";
import Employee from "./pages/Employee";
import Services from "./pages/Services";
import Report from "./pages/Report";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <div className="flex flex-col min-h-screen">
                    <Navigation />
                    <main className="flex-grow">
                      <div className="max-w-7xl mx-auto px-4 py-6">
                        <Routes>
                          <Route path="/home" element={<Home />} />
                          <Route path="/customers" element={<Customers />} />
                          <Route path="/employee" element={<Employee />} />
                          <Route path="/services" element={<Services />} />
                          <Route path="/report" element={<Report />} />
                        </Routes>
                      </div>
                    </main>
                  </div>
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;