import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Search, PlusCircle, Edit2, Trash2, FileText, Tag, X, Save, DollarSign } from 'lucide-react';

Modal.setAppElement("#root");

function Services() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [serviceForm, setServiceForm] = useState({
    name: "",
    price: "",
    status: "active",
  });

  // Veritabanından aktif işlemleri çekme
  const fetchServices = async () => {
    try {
      const q = query(
        collection(db, "services"),
        where("status", "==", "active")
      );
      const querySnapshot = await getDocs(q);
      const servicesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesData);
      setFilteredServices(servicesData);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  // Arama çubuğu
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = services.filter((service) =>
      service.name.toLowerCase().includes(value)
    );
    setFilteredServices(filtered);
  };

  // Modal açma/kapatma
  const openModal = (service = null) => {
    setModalIsOpen(true);
    if (service) {
      setIsEditing(true);
      setSelectedServiceId(service.id);
      setServiceForm({ name: service.name, price: service.price });
    } else {
      setIsEditing(false);
      setSelectedServiceId(null);
      setServiceForm({ name: "", price: "", status: "active" });
    }
  };

  const closeModal = () => setModalIsOpen(false);

  // İşlem kaydetme (benzersiz isim kontrolü)
  const handleSaveService = async () => {
    try {
      const servicesRef = collection(db, "services");
      const duplicateQuery = query(
        servicesRef,
        where("name", "==", serviceForm.name),
        where("status", "==", "active")
      );
      const querySnapshot = await getDocs(duplicateQuery);

      const isDuplicate = querySnapshot.docs.some(
        (doc) => doc.id !== selectedServiceId
      );

      if (isDuplicate) {
        alert("Bu isimde bir işlem zaten var!");
        return;
      }

      if (isEditing) {
        const serviceRef = doc(db, "services", selectedServiceId);
        await updateDoc(serviceRef, serviceForm);
        alert("İşlem güncellendi!");
      } else {
        await addDoc(servicesRef, serviceForm);
        alert("Yeni işlem eklendi!");
      }

      fetchServices();
      closeModal();
    } catch (error) {
      console.error("İşlem kaydedilirken hata:", error);
    }
  };

  // Soft delete
  const handleDeleteService = async (serviceId) => {
    try {
      const serviceRef = doc(db, "services", serviceId);
      await updateDoc(serviceRef, { status: "deleted" });
      alert("İşlem silindi (soft delete).");
      fetchServices();
    } catch (error) {
      console.error("Silme hatası:", error);
    }
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        {/* Arama Alanı */}
        <div className="relative w-full md:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="İşlem Ara"
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Yeni İşlem Butonu */}
        <button
          onClick={() => openModal()}
          className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
        >
          <PlusCircle className="h-5 w-5" />
          Yeni İşlem
        </button>
      </div>

      {/* İşlem Kartları Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredServices.map((service) => (
          <div
            key={service.id}
            className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <div className="p-6">
              {/* İşlem İsmi */}
              <div className="flex items-start mb-4">
                <FileText className="h-5 w-5 text-blue-500 mt-1 mr-2 flex-shrink-0" />
                <div>
                  <h3 className="font-medium text-gray-900">{service.name}</h3>
                </div>
              </div>

              {/* Fiyat */}
              <div className="flex items-center mb-6">
                <Tag className="h-5 w-5 text-green-500 mr-2" />
                <span className="text-lg font-semibold text-green-600">
                  {service.price} TL
                </span>
              </div>

              {/* Aksiyon Butonları */}
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => openModal(service)}
                  className="inline-flex items-center px-3 py-1.5 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                >
                  <Edit2 className="h-4 w-4 mr-1" />
                  Düzenle
                </button>
                <button
                  onClick={() => handleDeleteService(service.id)}
                  className="inline-flex items-center px-3 py-1.5 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
                >
                  <Trash2 className="h-4 w-4 mr-1" />
                  Sil
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '500px',
            width: '90%',
            margin: '0 auto',
            padding: 0,
            border: 'none',
            background: 'transparent',
            overflow: 'visible'
          }
        }}
      >
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          {/* Modal Header */}
          <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white flex items-center gap-2">
              <FileText className="h-5 w-5" />
              {isEditing ? "İşlem Düzenle" : "Yeni İşlem"}
            </h2>
            <button
              onClick={closeModal}
              className="text-white hover:text-gray-200 transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Body */}
          <div className="p-6 space-y-4">
            {/* İşlem Adı */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <FileText className="h-4 w-4 text-blue-500" />
                İşlem Adı
              </label>
              <input
                type="text"
                placeholder="İşlem Adı"
                value={serviceForm.name}
                onChange={(e) => setServiceForm({ ...serviceForm, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Fiyat */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <DollarSign className="h-4 w-4 text-blue-500" />
                Fiyat
              </label>
              <input
                type="number"
                placeholder="Fiyat"
                value={serviceForm.price}
                onChange={(e) => setServiceForm({ ...serviceForm, price: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          {/* Modal Footer */}
          <div className="bg-gray-50 px-6 py-4 flex justify-end gap-3">
            <button
              onClick={closeModal}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
            >
              İptal
            </button>
            <button
              onClick={handleSaveService}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
            >
              <Save className="h-4 w-4" />
              {isEditing ? "Güncelle" : "Kaydet"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Services;
