import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { 
  Calendar, 
  DollarSign, 
  Percent, 
  Search, 
  CreditCard,
  User 
} from 'lucide-react';

function Report() {
  const navigate = useNavigate();
  const [bills, setBills] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [customers, setCustomers] = useState([]);

  const fetchBills = async () => {
    try {
      // Önce müşteri verilerini çek
      const [customerSnapshot, billsSnapshot] = await Promise.all([
        getDocs(query(collection(db, "users"), where("role", "==", "customer"))),
        getDocs(query(
          collection(db, "bills"),
          ...(startDate && endDate 
            ? [
                where("date", ">=", startDate),
                where("date", "<=", endDate),
                where("status", "==", "active")
              ]
            : [where("status", "==", "active")]
          )
        ))
      ]);

      // Müşteri verilerini map'le
      const customersData = customerSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCustomers(customersData);

      // Bills verilerini müşteri bilgileriyle birleştir
      const fetchedBills = billsSnapshot.docs.map((doc) => {
        const billData = doc.data();
        const customer = customersData.find((c) => c.id === billData.customerId);

        return {
          id: doc.id,
          ...billData,
          customerName: customer ? `${customer.name} ${customer.surname}` : "Bilinmiyor"
        };
      });

      setBills(fetchedBills);

      // Toplam gelir ve indirim hesaplama
      const income = fetchedBills.reduce((sum, bill) => sum + Number(bill.totalPrice || 0), 0);
      const discount = fetchedBills.reduce((sum, bill) => sum + Number(bill.discount || 0), 0);

      setTotalIncome(income);
      setTotalDiscount(discount);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  useEffect(() => {
    fetchBills();
  }, []);

  return (
    <div className="p-6">
      {/* Header - Tarih Filtreleri */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-4 flex items-center gap-2">
          <Calendar className="h-5 w-5 text-blue-500" />
          Tarih Aralığı
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-end">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Başlangıç Tarihi
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Bitiş Tarihi
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div className="flex items-center">
            <button
              onClick={fetchBills}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
            >
              <Search className="h-4 w-4" />
              Filtrele
            </button>
          </div>
        </div>
      </div>

      {/* Özet Kartları */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {/* Toplam Gelir Kartı */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-100 rounded-full">
              <DollarSign className="h-6 w-6 text-green-600" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Toplam Gelir</h3>
              <p className="text-2xl font-semibold text-gray-900">{totalIncome.toLocaleString('tr-TR')} TL</p>
            </div>
          </div>
        </div>

        {/* Toplam İndirim Kartı */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-red-100 rounded-full">
              <Percent className="h-6 w-6 text-red-600" />
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-500">Toplam İndirim</h3>
              <p className="text-2xl font-semibold text-gray-900">{totalDiscount.toLocaleString('tr-TR')} TL</p>
            </div>
          </div>
        </div>
      </div>

      {/* Adisyon Tablosu */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tarih
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Müşteri
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fiyat
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  İndirim
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ödeme Türü
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {bills.map((bill) => (
                <tr key={bill.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(bill.date).toLocaleDateString('tr-TR')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4 text-gray-500" />
                      {bill.customerName}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {bill.discount > 0 ? (
                      <div className="flex items-center gap-2">
                        <span className="line-through text-gray-500">
                          {bill.price.toLocaleString('tr-TR')} TL
                        </span>
                        <span className="text-green-600 font-medium">
                          {bill.totalPrice.toLocaleString('tr-TR')} TL
                        </span>
                      </div>
                    ) : (
                      <span className="text-green-600 font-medium">
                        {bill.totalPrice.toLocaleString('tr-TR')} TL
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-red-600 font-medium">
                    {(bill.discount || 0).toLocaleString('tr-TR')} TL
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <CreditCard className="h-4 w-4 text-gray-500" />
                      {bill.paymentType}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Report;