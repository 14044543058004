export const validateBill = (billData) => {
  const errors = {};

  // Tarih kontrolü
  if (!billData.date) {
    errors.date = 'Tarih zorunludur';
  } else if (new Date(billData.date) > new Date()) {
    errors.date = 'Gelecek tarihli adisyon girilemez';
  }

  // Fiyat kontrolü
  if (!billData.price) {
    errors.price = 'Fiyat zorunludur';
  } else if (billData.price <= 0) {
    errors.price = 'Fiyat 0\'dan büyük olmalıdır';
  } else if (!/^\d+(\.\d{1,2})?$/.test(billData.price.toString())) {
    errors.price = 'Geçerli bir fiyat giriniz';
  }

  // İndirim kontrolü
  if (billData.discount) {
    if (billData.discount < 0) {
      errors.discount = 'İndirim 0\'dan küçük olamaz';
    } else if (billData.discount > billData.price) {
      errors.discount = 'İndirim tutarı fiyattan büyük olamaz';
    }
  }

  // Ödeme türü kontrolü
  if (!['Nakit', 'Kredi Kartı', 'Havale', 'Açık Hesap', 'Açık Hesap Ödeme'].includes(billData.paymentType)) {
    errors.paymentType = 'Geçersiz ödeme türü';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};